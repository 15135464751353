import React from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import pic1 from '../assets/images/DAApic.png';
// import pic2 from '../assets/images/pic02.png';
// import pic3 from '../assets/images/pic03.jpg';
import config from '../../config';
const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
        <ul className="actions special">
          <li>
            {/* <Scroll type="id" element="two"> */}
            <a href="/Library-of-3D-heart-models" className="button primary">
              Explore
            </a>
            {/* </Scroll> */}
          </li>
        </ul>
      </div>
      <Scroll type="id" element="one">
        <a href="#one" className="more">
          Learn More
        </a>
      </Scroll>
    </section>

    <section id="one" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>
            Welcome to 3D Hearter
            <br /> The library of anatomical heart models
          </h2>
          <p>
            You can study and explore the structure of heart defects online
            <br /> or get them printed out in 3D!
          </p>
        </header>
        <ul className="icons major">
          <li>
            <span className="icon fa-gem major style1">
              <span className="label">Lorem</span>
            </span>
          </li>
          <li>
            <span className="icon fa-heart major style1">
              <span className="label">Ipsum</span>
            </span>
          </li>
          <li>
            <span className="icon solid fa-code major style1">
              <span className="label">Dolor</span>
            </span>
          </li>
        </ul>
      </div>
    </section>

    <section id="two" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <img src={pic1} alt="" />
        </div>
        <div className="content">
          <a href="double-aortic-arch">
            <h2>Double Aortic Arch</h2>
          </a>
          <p>
            An anomaly of the aortic arch in which two aortic arches form a
            complete vascular ring.
          </p>
          <br />
          <p>
            <ul className="alt">
              <li>
                <a
                  href="/Library-of-3D-heart-models"
                  className="button primary"
                >
                  Continue to models >>
                </a>
              </li>
            </ul>
          </p>
        </div>
      </section>
      {/* <section className="spotlight">
        <div className="image">
          <img src={pic2} alt="" />
        </div>
        <div className="content">
          <h2>Tetralogy of Fallot</h2>
          <p>
            Congenital heart defect which usually combines four different
            malformations.
          </p>
          <br />
          <p>
            {' '}
            <a href="tetralogy-of-fallot">Click here to see more</a>
          </p>
        </div>
      </section> */}
      {/* <section className="spotlight">
        <div className="image">
          <img src={pic3} alt="" />
        </div>
        <div className="content">
          <h2>
            Augue eleifend aliquet
            <br />
            sed condimentum
          </h2>
          <p>
            Aliquam ut ex ut augue consectetur interdum. Donec hendrerit
            imperdiet. Mauris eleifend fringilla nullam aenean mi ligula.
          </p>
        </div>
      </section> */}
    </section>

    <section id="three" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2>Features</h2>
          <p>
            This library of the 3D heart models was created with enthusiasm and
            fascination by the human heart.
            <br /> The understanding of cardiac defects is based on detailed
            knowledge of the morphology, mutual anatomical relationships of
            cardiac structures and comprehension of the unique pathopsysiology.
          </p>
        </header>
        <ul className="features">
          <br />
          <li className="icon solid fa-heart">
            <h3>Study 3D models online for free</h3>
            <p>
              Learn about different heart defects with the precise{' '}
              <b>three-dimensional visualisation</b>. Explore the models from
              every possible angle.
            </p>
          </li>
          <li className="icon solid fa-hand-holding-heart">
            <h3>Get a 3D printed version</h3>
            <p>
              Need a booster material for studying or teaching? Sit by the books
              looking at a{' '}
              <b>
                <a href="https://biovoxel.tech/en/services/organ-models/">
                  real heart model
                </a>{' '}
              </b>{' '}
              in your own hands and study them in detail.
            </p>
          </li>
          <li className="icon solid fa-user-md">
            <h3>Understand the complexity</h3>
            <p>
              Connecting memorized knowledge with the true structure of{' '}
              <b>the heart abnormality</b> gives you an advantage compared to
              books or pictures.
            </p>
          </li>
          <li className="icon solid fa-bolt">
            <p>
              <h3>Have an interesting case?</h3>
            </p>
            <p>
              This 3D heart library is still in a progress,{' '}
              <b>you can contribute</b> with your DICOM or .stl file :) Contact
              me to find out more.{' '}
            </p>
          </li>
        </ul>
      </div>
    </section>

    <section id="cta" className="wrapper style4">
      <div className="inner">
        <header>
          <h2>3D modeling & 3D printing</h2>
          <p>
            Do you need an accurate virtual or printed 3D model based on your CT
            or MRI data? Leave a message!
          </p>
        </header>
        <ul className="actions stacked">
          <li>
            <br />
            <br />
            <a
              href="mailto:ad.furdova@gmail.com"
              className="button fit primary"
            >
              Contact
            </a>
          </li>
          {/* <li>
            <a href="#three" className="button fit">
              Learn More
            </a>
          </li> */}
        </ul>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
